import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
		<nav aria-label="breadcrumb" class="breadcrumbs-section border-top border-bottom mb-0">
			<div class="container">
				<ol class="breadcrumb mb-0 py-2 px-0">
					<li class="breadcrumb-item">
					<a href="/">Home</a></li>
					<li class="breadcrumb-item active">Page not found</li>
				</ol>
			</div>
		</nav>
		<section class="article-section py-8 pt-md-10 pb-md-7">
			<div class="container">
				<div class="row">
					<div class="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
						<h1 class="h1 fw-800 mb-5">Page not found</h1>
            <p>We're sorry, the page you are looking for has either moved, been deleted or does not exist.</p>
            </div>
            </div>
          </div>
        </section>
      </Layout>
)

export default NotFoundPage
